<template>
  <div id="app">
    <!-- HEADER -->
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav class="bg-gray-800">
      <div class="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <!-- Mobile menu button-->
            <button
              @click="showMenu = !showMenu"
              type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>
              <!--
                Icon when menu is closed.

                Heroicon name: outline/menu

                Menu open: "hidden", Menu closed: "block"
              -->
              <svg
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!--
                Icon when menu is open.

                Heroicon name: outline/x

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg
                class="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div
            class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
          >
            <div
              class="flex-shrink-0 flex items-center text-gray-200 font-bold"
            >
              QASRE ZAINABIA
            </div>
            <div class="hidden sm:block sm:ml-6">
              <div class="flex space-x-4">
                <!-- Current: "bg-gray-900 text-gray-200", Default: "text-gray-300 hover:bg-gray-700 hover:text-gray-200" -->
                <router-link
                  v-for="(link, label) in links"
                  :to="link"
                  :key="link"
                  class="hover:bg-gray-900 text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  :class="selectedRoute == link ? 'bg-gray-900' : ''"
                  >{{ label }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div v-show="showMenu" class="sm:hidden" id="mobile-menu">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <!-- Current: "bg-gray-900 text-gray-200", Default: "text-gray-300 hover:bg-gray-700 hover:text-gray-200" -->
          <router-link
            v-for="(link, label) in links"
            :to="link"
            :key="link"
            class="hover:bg-gray-900 text-gray-200 block px-3 py-2 rounded-md text-base font-medium"
            :class="selectedRoute == link ? 'bg-gray-900' : ''"
            >{{ label }}</router-link
          >
        </div>
      </div>
    </nav>
    <!-- HEADER END -->

    <!-- BODY -->
    <router-view :key="$route.fullPath"></router-view>
    <!-- BODY END -->

    <!-- FOOTER -->
    <footer class="bg-gray-800 w-full py-6">
      <!-- marging just for display-->
      <div class="items-center justify-between my-4">
        <div class="flex justify-center w-full">
          <a href="https://www.facebook.com/IBQEZ/" target="_blank">
            <svg
              class="h-4 w-4 fill-current hover:text-blue-700 text-gray-200 mr-6"
              viewBox="0 0 512 512"
            >
              <path
                d="M455.27,32H56.73A24.74,24.74,0,0,0,32,56.73V455.27A24.74,24.74,0,0,0,56.73,480H256V304H202.45V240H256V189c0-57.86,40.13-89.36,91.82-89.36,24.73,0,51.33,1.86,57.51,2.68v60.43H364.15c-28.12,0-33.48,13.3-33.48,32.9V240h67l-8.75,64H330.67V480h124.6A24.74,24.74,0,0,0,480,455.27V56.73A24.74,24.74,0,0,0,455.27,32Z"
              />
            </svg>
          </a>
          <a href="https://www.youtube.com/channel/UCP_wFaVmsZbdX-mqLWVM8Tg" target="_blank">
            <svg
              class="h-4 w-4 fill-current hover:text-red-600 text-gray-200"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
              />
            </svg>
          </a>
        </div>
        <p class="text-gray-200 text-center mt-4 text-xs">
          © Copyright
          <a
            class="text-gray-200 hover:text-gray-500"
            href="https://www.suhprod.com"
            target="_blank"
            >S.U.H</a
          >
        </p>
      </div>
    </footer>
    <!-- FOOTER END -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "App",
  components: {},
  data() {
    return {
      showMenu: false,
      selectedRoute: "/",
    };
  },
  watch: {
    $route(to) {
      this.selectedRoute = to.path;
      if (this.selectedRoute.length < 1) {
        this.selectedRoute = "/";
      }
      this.showMenu = false;
    },
  },
  computed: {
    links() {
      return this.$store.state.navLinks;
    },
  },
};
</script>
