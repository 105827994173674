import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navLinks: {
      Home: "/",
      Gallery: "/gallery",
      "Our Majalis": "/our-majalis",
      Majalis: "/majalis",
      History: "/history",
    },
    try: 'reveal_it',
    galleryZiaraat: [
      { file: "imam_ali.jpeg", name: "Imam Ali (a.s)" },
      { file: "baqi.gif", name: "Jannat ul Baqi" },
      { file: "imam_hussain.jpeg", name: "Imam Hussain (a.s)" },
      { file: "mola_abbas.jpeg", name: "Mola Abbas (a.s)" },
      { file: "karbala.jpeg", name: "Karbala Moalla" },
      { file: "bibi_zainab.jpeg", name: "Bibi Zainab (a.s)" },
      { file: "imam_musa_taqi.jpeg", name: "Imam Musa Kazim & Imam Taqi (a.s)" },
      { file: "imam_raza.jpeg", name: "Imam Ali Raza (a.s)" },
      { file: "imam_askari.jpeg", name: "Imam Hassan Askari (a.s)" },
    ],
    galleryBargah: [
      "bargah/background1.jpeg",
      "bargah/background2.jpeg",
      "bargah/background3.jpeg",
      "bargah/background4.jpeg",
      "bargah/background5.jpeg",
      "bargah/background6.jpeg",
    ],
    our_majalis: {
      Malakwal: {
        "17 Safar 2020 - Zakir Wasim Abbas Blouch": "https://www.youtube.com/embed/mj-p1HUYBfM",
        "17 Safar 2020 - Juloos Zuljanah": "https://www.youtube.com/embed/R9KXKN0PfZU",
        "17 Safar 2020 - Zakir Zaigham Abbas Tiba Botay Shah": "https://www.youtube.com/embed/fv0MCZ1U308",
        "17 Safar 2020 - Zakir Syed Amir Abbas Rubani": "https://www.youtube.com/embed/Mp_7Dnp2uwk",
        "17 Safar 2020 - Zakir Shafqat Mohsin Kazami": "https://www.youtube.com/embed/JhpAK35nO9g",
        "17 Safar 2020 - Allama Sajid Taqvi": "https://www.youtube.com/embed/J5beggeROAs",
        "17 Safar 2020 - Zakir Haji Nasir Abbas Notak": "https://www.youtube.com/embed/L-KOBeHRfTE",
        "17 Safar 2020 - Juloos Alam Pak": "https://www.youtube.com/embed/UhgD7z6vUpc",
        "17 Safar 1997": "https://www.youtube.com/embed/MynrII27bf0",
        "17 Safar 1996": "https://www.youtube.com/embed/hRzNV9YYs2Y",
        "17 Safar 1995": "https://www.youtube.com/embed/e6PeNZTMXmA",
        "17 Safar 1994": "https://www.youtube.com/embed/LQs85Zlvym4",
        "17 Safar 1993": "https://www.youtube.com/embed/TAUchwoPwOk",
        "17 Safar 1992": "https://www.youtube.com/embed/uFyjAynPz2I",
        "17 Safar 1991": "https://www.youtube.com/embed/huU0no_527g",
      },
      Bangla: {
        "5th, 6th & 9th Muharram 2000": "https://www.youtube.com/embed/x75YJm24P9Y",
        "10th Muharram 1997": "https://www.youtube.com/embed/e-oobQhVE4g",
        "8th & 9th Muharram 1997": "https://www.youtube.com/embed/nQQbByrDhFA",
        "5th & 6th Muharram 1997": "https://www.youtube.com/embed/V7FfuVvGGec",
        "5th 8th & 10th Muharram 1990": "https://www.youtube.com/embed/pK8Ho6zbQ8g",
        "9th & 10th Muharram 1989": "https://www.youtube.com/embed/fzZmBEhfhb0",
      },
    },
    other_majalis: {
      "Allama Muhammad Ali Hasnain": {
        'Imam e Zamana (a.s) - Ashra': "https://www.youtube.com/embed?listType=playlist&list=PLJoJ-SGCGd8xWKjwAQyXOmEMVkVAsqN0c",
        'Mola Ali (a.s) - Betul Hozan': "https://www.youtube.com/embed/4I9luKw1JRY",
        'Mola Imam Ali (a.s)': "https://www.youtube.com/embed/IDxknwqQXHY",
        'Shahadat Mola Imam Ali (a.s)': "https://www.youtube.com/embed/Z-O48cjqnFI",
        'Wilayat E Ali (a.s) Or Marifat E Hujjat': "https://www.youtube.com/embed/HbBmgznxe9U",
        'Mola Imam Hussain (a.s)': "https://www.youtube.com/embed/tgpYzgax218",
        'Mola Imam Hassan (a.s)': "https://www.youtube.com/embed/LdDpbSG-zso",
        'Mola Abbas (a.s)': "https://www.youtube.com/embed/HD4nfjPAqj8",
        'Khamsa Majlis': "https://www.youtube.com/embed/SWD7j1EEIPU",
        'Majlis o Matam ki Azmat': "https://www.youtube.com/embed/4tri_vN2E1M",
        'Janab e Behlol': "https://www.youtube.com/embed/Oce4kDjTSu0",
      },
      "Allama Ali Baqir Naqvi Shaheed": {
        'Fazail Aal e Muhammad - Ashra': "https://www.youtube.com/embed?listType=playlist&list=PLnZueVjDds7_b90o8dfL7Vm-t2wwjl8vg&index=4",
        'Topic Ali Wali Allah - Jafaria Colony Lahore - Ashra': "https://www.youtube.com/embed?listType=playlist&list=PLJRS_xgO650DSfKoSPQLp9h-s2lZeTle8",
        'Fazilat e Mola e Abbas (a.s)': "https://www.youtube.com/embed?listType=playlist&list=PLnZueVjDds79IeFrxeBSJqxDpLE_vCe4f",
        'Fazail e Mola e Abbas (a.s)': "https://www.youtube.com/embed/26p2r942ZkQ",
        'Mola Abbas (a.s)': "https://www.youtube.com/embed/Cyr1ybTB62U",
        'Fazail Mola Abbas (a.s)': "https://www.youtube.com/embed/uKuLmpWQyQc",
        'Mola Abbas a.s': "https://www.youtube.com/embed/ydbNSyw2VEE",
        'Willayet e Israria': "https://www.youtube.com/embed/RTKeO8g2KpQ",
        'Jashan Moula Hassan (a.s)': "https://www.youtube.com/embed/PuqpmS64nSE",
        'Imam Sajjad (a.s)': "https://www.youtube.com/embed/TSr-AvfFiIo",
        'Topic Beyat': "https://www.youtube.com/embed/fH3PAHa-mek",
        'Topic Hujjat Aur Sajda': "https://www.youtube.com/embed/etM4V5yktS8",
        'Topic Safeer': "https://www.youtube.com/embed/czCR4IpZcTE",
      },
      "Allama Zameer Akhter": {
        'Nuseri': "https://www.youtube.com/embed/rWxWjYoXPrA",
        'Quran Aur Musa (as)': "https://www.youtube.com/embed/m25wZtkKKLc",
        'Surah e Jin (as)': "https://www.youtube.com/embed/QZLS0EUYYm0",
      },
      "Allama Nasir Abbas Shaheed": {
        "Imam Musa Kazim (a.s)": "https://www.youtube.com/embed/Wa3qYhfXpTA",
      },
      "Zakir Nabi Bakhsh Joyah": {
        'Shahadat Mola Hussain (a.s)': "https://www.youtube.com/embed/uU__Xylds0A",
        'Mola Hussain (a.s)': "https://www.youtube.com/embed/1LW7v52fzR0",
        'Shahadat Ali Akbar (a.s)': "https://www.youtube.com/embed/VsdBFWaGAaM",
        'Bibi Paak Zainab (a.s)': "https://www.youtube.com/embed/I8w4cZ28zTI",
      },
      "Zakir Riaz Hussain Shah": {
        'Shahadat Imam Musa Kazim (a.s)': "https://www.youtube.com/embed/jDPU0zWn7t8",
      },
      "Zakir Khadim Hussain Shah of Chak 38": {
        'Darbar Shah Chan Charagh': "https://www.youtube.com/embed/7ZC5OhmwBSc",
      },
    },
    secret_majalis: {
      'On Jumma': "https://www.youtube.com/embed/liraSHDSU7M",
      'On Hujjat': "https://www.youtube.com/embed/TMCrHS_R_6U",
      'Mola Hussain a.s (Mir Anees)': "https://www.youtube.com/embed/RpKTlv5y6QA",
    }
  },
  mutations: {},
  actions: {},
  modules: {},
});
